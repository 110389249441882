const REGEXP_WIDTH = /-([0-9a-v]+)x[0-9a-v]+$/
const REGEXP_HEIGHT = /x([0-9a-v]+)$/

const memoize = fn => {
  const cache = {}
  return id => {
    if (cache[id]) return cache[id]
    cache[id] = fn(id)
    return cache[id]
  }
}

export const width = memoize(id => parseInt(id.match(REGEXP_WIDTH)[1], 32))

export const height = memoize(id => parseInt(id.match(REGEXP_HEIGHT)[1], 32))

export const constrainedWidth = memoize(id => {
  const constraint = 1200
  const actualWidth = width(id)
  const scaleRatio = constraint / actualWidth
  return actualWidth * scaleRatio
})

export const constrainedHeight = memoize(id => {
  const constraint = 1200
  const actualWidth = width(id)
  const actualHeight = height(id)
  const scaleRatio = constraint / actualWidth
  return actualHeight * scaleRatio
})

export const src = (id, size) =>
  `https://kadr-backend-production.s3.amazonaws.com/${size}/${id}.jpg`

export const thumbSizeType = () =>
  window.innerWidth > 440 ? 'preview' : 'thumbnail'
