import { Box, Text } from 'gestalt'
import 'gestalt/dist/gestalt.css'
import React from 'react'
import {
  CellMeasurer,
  CellMeasurerCache,
  createMasonryCellPositioner,
  Masonry
} from 'react-virtualized'
import 'react-virtualized/styles.css'
import './App.css'
import * as photoIdUtils from './photo-id'
import photoIds from './photos'

//const Item = ({ data }) => <Image {...data} />

const photos = photoIds
  .map(photoId => photoId.replace('.jpg', ''))
  .map(photoId => ({
    alt: 'image',
    color: '#eee',
    src: photoIdUtils.src(photoId, 'thumbnail'),
    largeSrc: photoIdUtils.src(photoId, 'original'),
    naturalHeight: photoIdUtils.height(photoId),
    naturalWidth: photoIdUtils.width(photoId)
  }))

// Array of images with captions
const list = photos

// Default sizes help Masonry decide how many images to batch-measure
const cache = new CellMeasurerCache({
  defaultHeight: 250,
  defaultWidth: 200,
  fixedWidth: true
})

// Our masonry layout will use 3 columns with a 10px gutter between
const cellPositioner = createMasonryCellPositioner({
  cellMeasurerCache: cache,
  columnCount: Math.floor(window.innerWidth / 190),
  columnWidth: 190,
  spacer: 0
})

function cellRenderer({ index, key, parent, style }) {
  const datum = list[index]

  return (
    <CellMeasurer cache={cache} index={index} key={key} parent={parent}>
      <div style={style}>
        <img
          src={datum.src}
          onClick={() => window.open(datum.largeSrc)}
          style={{
            height: datum.naturalHeight * (190 / datum.naturalWidth),
            width: 190,
            padding: '4px',
            boxSizing: 'border-box',
            cursor: 'pointer'
          }}
        />
        <Box marginTop={-1} paddingX={1}>
          <Text size="xs">#{index}</Text>
        </Box>
        <Box padding={1} />
      </div>
    </CellMeasurer>
  )
}

function App() {
  return (
    <div
      style={{
        margin: '0 auto',
        width: `${Math.floor(window.innerWidth / 190) * 190}px`
      }}
    >
      <Masonry
        cellCount={list.length}
        cellMeasurerCache={cache}
        cellPositioner={cellPositioner}
        cellRenderer={cellRenderer}
        height={window.innerHeight}
        width={window.innerWidth}
      />
    </div>
    // <Masonry
    //   comp={Item}
    //   items={photos}
    //   minCols={1}
    //   virtualize={true}
    //   scrollContainer={() => window}
    // />
  )
}

export default App
